@use "../assets/styles/mixins" as mixin;

.slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
  &:hover {
    .counter {
      opacity: 1;
    }
  }

  &__rightArrow {
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 51%;
    right: 2%;
    transform: translateY(-50%);
    @include mixin.xs {
      height: 25px;
    }
  }

  &__leftArrow {
    height: 30px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
    @include mixin.xs {
      height: 25px;
    }
  }

  &__slides {
    width: 1240px;
    height: 415px;
    object-fit: cover;
    border-radius: 25px;

    @include mixin.xs {
      height: 255px;
    }
  }
}

.counter {
  position: absolute;
  bottom: 8%;
  left: 50%;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}
