@use "../assets/styles/mixins" as mixin;

.redStar {
  margin-left: 5px;
  @include mixin.lg-lt {
    width: 20px;
  }
  @include mixin.xs {
    width: 15px;
  }
}
.greyStar {
  margin-left: 5px;
  @include mixin.lg-lt {
    width: 20px;
  }
  @include mixin.xs {
    width: 15px;
  }
}
