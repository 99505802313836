body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding-left: 0px;
  margin: 0;
}

img {
  max-width: 100%;
}

input {
  font-family: var(--font-family);
}

::placeholder {
  font-family: var(--font-family);
}

textarea {
  font-family: var(--font-family);
}

a:link {
  text-decoration: none;
  color: var(--primary);
}
