@use "../assets/styles/mixins" as mixin;

.card {
  position: relative;
  height: 340px;
  width: 340px;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);

    img {
      transform: scale(1.06);
      opacity: 0.8;
    }
  }

  &__picture {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: all 1.2s;

    &__text {
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 40%;
      border-radius: 0 0 10px 10px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 13%,
        rgba(0, 212, 255, 0) 70%
      );

      &__title {
        font-size: 18px;
        font-weight: 500;
        color: white;
        position: absolute;
        bottom: 20px;
        left: 10px;
      }
    }
  }
}
