@use "../assets/styles/mixins" as mixin;

.home {
  max-width: 1240px;
  margin: auto;
  width: 90%;

  &__tagline {
    height: 223px;
    border-radius: 25px;
    margin: auto;
    margin-bottom: 50px;
    position: relative;
    background-color: black;
    @include mixin.lg-lt {
      height: 180px;
      margin-bottom: 40px;
    }
    @include mixin.xs {
      margin-bottom: 0px;
    }

    &__picture {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 25px;
      opacity: 0.7;
    }

    &__text {
      color: white;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 42px;
      font-weight: 500;
      width: 100%;
      text-align: center;

      @include mixin.lg-lt {
        font-size: 35px;
      }
      @include mixin.sm-lt {
        font-size: 24px;
      }
    }
  }

  &__location {
    background-color: #f6f6f6;
    margin: auto;
    width: 100%;
    border-radius: 25px;
    padding: 40px 0;
    @include mixin.sm-lt {
      background-color: white;
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      width: 90%;
      margin: auto;
      gap: 40px;

      @include mixin.md-lt {
        gap: 20px;
        width: 95%;
      }
    }
  }
}
