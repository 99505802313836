@use "../assets/styles/mixins" as mixin;

.about {
  max-width: 1240px;
  margin: auto;
  width: 90%;
  min-height: 100vh;

  &__top {
    border-radius: 25px;
    margin: 0 auto 100px;
    height: 160px;
    @include mixin.lg-lt {
      height: 130px;
      margin: 0 auto 80px;
    }
    @include mixin.xs {
      margin: 0 auto 50px;
    }

    &__picture {
      object-fit: cover;
      border-radius: 25px;
      @include mixin.md-lt {
        height: 160px;
      }
    }
  }
}

.about__dropdown {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  padding-bottom: 50px;
  @include mixin.xs {
    gap: 15px;
  }

  &__content {
    width: 100%;
  }
}
